<template>
  <div class="pa-10">
    <title-page icon="ico-billingRequest">
      {{ $tc('billing-request.billing-request', 2) }}
    </title-page>


    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col>
                <date-picker
                    label="Date de création"
                    v-model="rangeDate"
                    clearable
                    hide-details
                    range
                />
              </v-col>
              <v-col>
                <date-picker
                    label="Date de la période"
                    v-model="rangePeriodDate"
                    clearable
                    hide-details
                    range
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <organization-select
                    :label="$t('organization.organization')"
                    clearable
                    v-model="filters.organizationId"
                />
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>




    <v-divider class="my-6"/>

    <v-card class="my-3" color="primary">
      <v-card-text class=" white--text" style="font-size: 20px;">
        <v-row>
          <v-col>
            <v-row>
              <v-col>Nb de demande de facturation</v-col>
              <v-col cols="auto" class="text-right"><b>{{ stats.total }}</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total HT</v-col>
              <v-col cols="auto" class="text-right"><b>{{ numberFormat(stats.netTotal) }} €</b></v-col>
            </v-row>
          </v-col>
          <v-divider inset vertical />
          <v-col>
            <v-row>
              <v-col>Total TTC</v-col>
              <v-col cols="auto" class="text-right"><b>{{ numberFormat(stats.finalTotal) }} €</b></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-divider class="my-6"/>


    <v-row class="align-center mb-2">
      <v-col>
        <v-menu
            v-if="selectedItems.length > 0"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="btn-actions-menu"
            >
              {{ $tc('selected-items', selectedItems.length, {count: selectedItems.length})  }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item dense
                link
                :ripple="false"
                @click="openTransmitInvoiceModal"
            >
              <v-list-item-title>{{ $t('billing-request.transmit-invoice') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col>
        <search-field v-model="search" clearable></search-field>
      </v-col>
    </v-row>


    <v-row>
      <v-col >
        <v-data-table
          class="br-table"
          :headers="headers"
          v-model="selectedItems"
          :items="billingRequests"
          :options.sync="options"
          :loading="loading"
          :server-items-length="total"
          show-select
        >
          <template #item.createdAt="{item}">
            {{ item.createdAt | formatDate($t('format_date')) }}
          </template>
          <template #item.startDate="{item}">
            {{ formatUtcDate(item.startDate, $t('format_date')) }} - {{ formatUtcDate(item.endDate, $t('format_date')) }}
          </template>

          <template #item.netTotal="{item}">
            {{ item.netTotal ? numberFormat(item.netTotal) + ' €' : '-' }}
          </template>

          <template #item.status="{item}">
            {{ $t('invoice.statues.' + item.status) }}
          </template>


          <template #item.options="{item}">
            <v-btn icon small @click="openBillingRequest(item)">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn small @click="downloadCsv">
          <v-icon left>mdi-download</v-icon>
          {{ $t('export') }}
        </v-btn>
      </v-col>
    </v-row>


    <v-dialog v-model="transmitInvoiceModal" max-width="800">
      <v-card>
        <v-card-title>
          <h3>Envoyer une facture</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3">
          <v-row>
            <v-col>
              Vous avez sélectionné <b>{{ selectedItems.length }}</b> demandes de facturation :
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="max-height: 300px; padding-top: 8px; overflow: scroll; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc">
                <v-chip class="ml-1 mb-2" :key="item.billingRequestReference" close v-for="(item, i) in selectedItems" @click:close="removeSelectedItem(i)">{{ item.billingRequestReference}}</v-chip>
              </div>
            </v-col>
          </v-row>



          <v-row>
            <v-col>
              Le montant attendu de la facture correspondant à ces demandes de facturation doit être égal à <b>{{ totalForSelectedItems }} € HT</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-file-input
                label="Fichier de la facture"
              ></v-file-input>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col class="ma-0 text-right">
              <v-btn small @click="transmitInvoiceModal = false">{{ $t('cancel') }}</v-btn>
              <v-btn class="ml-3" :disabled="selectedItems.length === 0" small color="primary" @click="transmitInvoiceModal = false">{{ $t('billing-request.transmit-invoice')}}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <billingRequest-show-modal ref="billingRequestModal" />

  </div>
</template>

<script>
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import BillingRequestShowModal from "@modals/BillingRequestShowModal.vue";
import BillingRequestRepository from "@repository/BillingRequestRepository";
import InvoiceRepository from "@repository/InvoiceRepository";

export default {
  components: {
    OrganizationSelect,
    BillingRequestShowModal,
  },
  data() {
    return {
      loading: false,
      transmitInvoiceModal: false,
      allOperators: [],
      selectedItems: [],
      rangeDate: [],
      rangePeriodDate: [],
      stats: {
        total: 0,
        netTotal: 0,
        finalTotal: 0,
      },
      filters: {
        organizationId: null,
      },
      total: 0,
      billingRequests: [],
      options: null,
    }
  },

  mounted() {
    this.loadOperators()
  },

  watch: {
    filters: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    },

    rangePeriodDate() {
      this.loadData()
    },

    rangeDate() {
      this.loadData()
    },

    options() {
      this.loadData()
    },

    recipientType() {
      this.showLoading(true)
      this.filters = {
        organizationId: null,
        contentType: null,
        customerId: null,
        operatorId: null,
        partnerOperators: null,
      }
    },



  },

  computed: {

    headers() {
      return [
        {text: this.$t('created-at'), value: 'createdAt'},
        {text: this.$t('billing-request.reference'), value: 'billingRequestReference'},
        {text: this.$t('period'), value: 'startDate'},
        {text: this.$t('name'), value: 'issuerName'},
        {text: this.$t('price-ht'), value: 'netTotal'},
        {text: this.$t('status'), value: 'status'},
        {value: 'options'},
      ]
    },

    totalForSelectedItems() {
      return this.numberFormat(this.selectedItems.reduce((acc, item) => acc + item.netTotal, 0))
    },


    operators() {
      return this.allOperators.filter(partner => partner.ocpi)
    },

    partners() {
      return this.allOperators.filter(partner => !partner.ocpi)
    },

  },

  methods: {

    loadData() {

      this.loading = true
      let data = {
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }

      if(this.rangePeriodDate?.length > 0) {
        data.startPeriodDate = this.rangePeriodDate[0]
        data.endPeriodDate = this.rangePeriodDate[1]
      }


      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      BillingRequestRepository.search(data).then(response => {
        this.stats = response.stats
        this.total = response.total
        this.billingRequests = response.items
        this.loading = false
        this.showLoading(false)
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
        this.showLoading(false)
      })
    },

    openBillingRequest(billingRequest) {
      console.log(billingRequest)
      this.$refs.billingRequestModal.open(billingRequest.id)
    },

    loadOperators() {
      BillingRequestRepository.search().then(operators => {
        this.allOperators = operators
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },


    downloadCsv() {
      this.showLoading(true)

      let data = {
        recipientType: this.recipientType,
        ...this.filters,
      }


      if(this.rangeDate?.length > 0) {
        data.startDate = this.rangeDate[0]
        data.endDate = this.rangeDate[1]
      }

      if(this.rangePeriodDate?.length > 0) {
        data.startPeriodDate = this.rangePeriodDate[0]
        data.endPeriodDate = this.rangePeriodDate[1]
      }

      BillingRequestRepository.exportCsv(data).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },


    openTransmitInvoiceModal() {
      this.transmitInvoiceModal = true
    },

    removeSelectedItem(index) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.selectedItems.splice(index, 1)
        }
      })
    }
  },

}
</script>

<style lang="scss">
.br-table {
  tr {
    &:first-child {
      th {
        &:first-child {
          i {
            padding-left: 10px;
          }

          .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
            background-color: transparent !important;
          }
        }
      }
    }
  }


}

</style>
